<template>
  <module-container route-name="cfecodes" show-toolbar :title="$t('cfecodes')">
    <template #route>
      <router-view />
    </template>

    <template #filters>
      <cfe-code-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CfeCodeFilters from "@/modules/cfecodes/components/CfeCodeFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { CfeCodeFilters },
})
export default class CfecodesIndex extends Mixins(PaginateProviderMixin) {}
</script>
